import { useEffect } from 'react'

export function useClickAwayListener(refs, callback) {
    function handleClickAwayListener(event) {
        if(Array.isArray(refs)) {
            const validRefs = refs.every((ref) => {
                return ref.current && !ref.current.contains(event.target)
            })

            if(validRefs) {
                callback()
            }
        }

        if(refs.current && !refs.current.contains(event.target)) {
            callback()
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleClickAwayListener)

        return () => window.removeEventListener("click", handleClickAwayListener)
    }, [refs])
}