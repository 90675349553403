import styled from 'styled-components'

const setAlignItems = (alignItems, page) => {
  if (alignItems) return alignItems
  if (page === "blog") return "stretch"
  if (page) return "stretch"

  return "center"
}

export const LayoutWrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 22rem 0 5.75rem;
  border: 2px solid #333;
  border-top-style: none;
  border-right-style: solid;
  border-bottom-style: none;
  border-left-style: solid;
  position: relative;

  @media(max-width: 1260px) {
    margin: 0;
    border-right-style: none;
    border-left-style: none;
  }
`
export const LayoutMain = styled.main`
    flex: 1;
    display: flex;
    flex-direction: ${({ page }) => ["work", "about", "post"].includes(page) ? "column" : "row"};
    align-items: ${({ alignItems, page }) => setAlignItems(alignItems, page)};
    padding-left: ${({ page }) => ["work", "post"].includes(page) ? 0 : "5rem"};
    padding-right: ${({ paddingRight, page }) => paddingRight ? paddingRight : page === "about" ? "5rem" : 0};
    padding-bottom: ${({ page }) => ["work", "about", "post"].includes(page) ? "90px" : "2rem"};;
    
    .about-wrapper {
      p {
        font-size: 1.2rem;
        line-height: 1.5;
        margin: 0px auto 1.6rem;
        font-family: Roboto Mono;
      }
    }

    @media(max-width: 1260px) {
      padding: 3rem;
    }

    @media(min-width: 2000px) {
      padding-left: 0;
    }

    @media(max-width: 600px) {
      padding: 1rem;
    }



`
export const Header = styled.header`
  border-bottom: 2px solid #333;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECEAE0;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  position: sticky;
  top: 0;
`

export const Footer = styled.footer`
  height: 90px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  color: white;
  text-align: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
`

export const Box = styled.div`
  height: inherit;
  padding-top: 0;
  width: 22rem;
  border-top: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  @media(max-width: 1260px) {
      display: none;
    }
`