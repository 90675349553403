import styled from 'styled-components'

export const CallToActionWrapper = styled.section`    
    height: 90px;
    width: 22rem;
    border-bottom: 2px solid black;
    border-left: 2px solid #eceae0;
    display: flex;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;

    @media(max-width: 1260px) {
        display: none;
    }
`