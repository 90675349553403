import React from 'react'
import * as S from './styled'

const Button = ({ color, children, to, openNewTab }) => {
    let target = openNewTab ? "_blank": null

    return (
        <S.Button to={to} target={target} rel="noopener noreferrer" color={color}>
            {children}
        </S.Button>
    )
}

export default Button