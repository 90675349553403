import styled from 'styled-components'
import { Link } from 'gatsby'

export const Empty = styled.div`
    height: 90px;
    width: 5.75rem;
    border-top: ${({placement}) => placement === "bottom" ? "2px solid #333" : "none"};
    border-bottom: ${({placement}) => placement === "top" ? "2px solid #333" : "none"};
    position: fixed;
    top: ${({placement}) => placement === "top" ? 0 : "initial"};
    left: 0;
    bottom: ${({placement}) => placement === "bottom" ? 0 : "initial"};
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media(max-width: 1260px) {
        display: none;
    }
`

export const IconWrapper = styled(Link)`
    fill: #bbb;
    width: 25px;
    height: 25px;
`