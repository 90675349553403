import React from 'react'

import Icons from './icons'
import * as S from './styled'

const Empty = ({ placement, arrowIconDirection, color, isActive }) => {
    const Icon = Icons[arrowIconDirection]
    return (
        <S.Empty placement={placement}>
            { isActive && (
                <S.IconWrapper to="/work">
                    <Icon color={color}/>
                </S.IconWrapper>
            )}
        </S.Empty>
    )
}

export default Empty