import styled from 'styled-components'
import { Link } from 'gatsby'

export const Button = styled(Link)`
    padding: 13px 40px;
    border-radius: 0;
    border: 2px solid black;
    background-color: ${({color}) => color ? color : "#5FA08C"};
    box-shadow: 7px 7px 0px 0px #000000;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 700;
    outline: none;
    font-family: Roboto Mono;
    display: inline-block;
    text-decoration: none;

    &, &:visited{
        color: #000;
    }

    &:active {
        box-shadow: 7px 3px 0px 0px #000000;
        transform: translateY(4px);
    }
`