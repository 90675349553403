import styled from 'styled-components'
import { Link } from 'gatsby'

export const MenuWrapper = styled.nav`
   padding: 0px 15px;
   width: 100%;
`

export const Menu = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;

    .logo {
        margin-right: auto;
        font-family: "Staatliches";
        font-size: 1.5rem;
        color: black;
        order: 1;
    }

    @media(max-width: 1000px) {
        flex-direction: row;

        .logo {
            order: 0;
        }
    }
`

export const MenuItem = styled.li`
   order: 3;
   margin: 0px 1.3rem;
   font-family: Roboto Mono;

   &:not(.logo):hover {
        font-weight: bolder;
   }
`

export const MenuItemLink = styled(Link)`
    text-decoration: none;
    display: block;
    padding: 15px 5px;
    color: #333;
`

export const MobileMenuWrapper = styled.div`
    display: none;

    @media(max-width: 1000px) {
        display: block;
        cursor: pointer;
    }
`

export const MobileMenuBar = styled.span`
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
`

export const NavigationWrapper = styled.nav`
    display: inherit;
    flex-wrap: inherit;
    align-items: inherit;
    order: 2;

    @media(max-width: 1000px) {
        display: ${({open}) => open ? "block" : "none" };
        position: fixed;
        right: 50px;
        top: 60px;
        border: 1px solid;
        background-color: #eceae0;
        width: 265px;
        border: 2px solid #333;

        & li {
            text-align: center;
            padding: 10px;
        }
    }


`