import React from 'react'

import * as S from './styled'
import Button from '../Button'

const CallToAction = ({ children }) => (
    <S.CallToActionWrapper>
        <Button to="/about">{children}</Button>
    </S.CallToActionWrapper>
)

export default CallToAction