import React, { useRef } from 'react'

import { useClickAwayListener } from '../customHooks'

import * as S from './styled'

const activeStyles = {
    color: "#8EC4B1",
}

const Menu = () => {
    const [open, setOpen] = React.useState(false)
    const menuRef = useRef(null)
    const buttonRef = useRef(null)

    useClickAwayListener([menuRef, buttonRef], handleClickAwayListener)

    function handleClick(){
        setOpen(!open)
    }

    function handleClickAwayListener() {
        setOpen(false)
    }

    return (
        <S.MenuWrapper>
            <S.Menu className="menu">
                <S.MenuItem className="logo">
                    <S.MenuItemLink to="/">Danilo Gila</S.MenuItemLink>
                </S.MenuItem>
                
                <S.NavigationWrapper open={open} ref={menuRef}>
                    <S.MenuItem>
                        <S.MenuItemLink to="/work" activeStyle={activeStyles}>projetos</S.MenuItemLink>
                    </S.MenuItem>
                    <S.MenuItem>
                        <S.MenuItemLink to="/about" activeStyle={activeStyles}>sobre mim</S.MenuItemLink>
                    </S.MenuItem>
                    <S.MenuItem>
                        <S.MenuItemLink to="/blog" activeStyle={activeStyles}>blog</S.MenuItemLink>
                    </S.MenuItem>
                </S.NavigationWrapper>
    
                <S.MobileMenuWrapper onClick={handleClick} ref={buttonRef}>
                    <S.MobileMenuBar></S.MobileMenuBar>
                    <S.MobileMenuBar></S.MobileMenuBar>
                    <S.MobileMenuBar></S.MobileMenuBar>
                </S.MobileMenuWrapper>
            </S.Menu>
        </S.MenuWrapper>
    )
}

export default Menu