/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Menu from "../Menu"

import * as S from './styled'

import "@fontsource/staatliches"
import "@fontsource/roboto-mono"
import "@fontsource/bungee"
import "@fontsource/bebas-neue"

import GlobalStyles from "../../styles/global"
import CallToAction from "../CallToAction"
import Empty from '../Empty'

const Layout = ({ children, alignItems, paddingRight, page }) => {
  return (
    <S.LayoutWrapper>
      <GlobalStyles />
      <Empty placement="top" arrowIconDirection="ArrowUp" color="#bcb591"/>
      <S.Header>
        <Menu />
        <CallToAction>Entre em contato</CallToAction>
      </S.Header>
      <S.LayoutMain alignItems={alignItems} paddingRight={paddingRight} page={page}>{children}</S.LayoutMain>
      <Empty placement="bottom" arrowIconDirection="ArrowDown" color="#333"/>
      <S.Footer>
        <S.Box />
      </S.Footer>
    </S.LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
